<template>
  <div class=" ">
    <titleBar
      title=" "
      :back="false"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="far"
      :isHome="false"
      :actions="[
        {
          icon: 'search',
          to: '/explore',
          hidden: false,
          hiddenTablet: false,
        },
        {
          icon: 'bell',
          to: '/notifications',
          hidden: false,
          hiddenTablet: false,
        },
        /*  {
          icon: 'comment',
          to: '/messages',
          hidden: false,
          hiddenTablet: false,
        },*/
      ]"
    />

    <div class="section" v-show="$route.query.ref == 'tabs'">
      <exploreTabs />
    </div>

    <div class="section">
      <Hero
        title="Discover"
        subtitle="Getting the most out of generative AI"
        bgImage="https://lh3.googleusercontent.com/D3-OahttrL-yH62XV-Ss1HzQWT1RaCeAowh_b24TSPUbfilOv559cGrzrExTklxin0oOrZ4RlyPYe85coUMZDSTCqgMOlsofyLm3RKFzhwONddZRf8X_=w2400-rj"
      />

      <md-chip-set>
        <md-assist-chip label="Assist"></md-assist-chip>
        <md-filter-chip label="Filter"></md-filter-chip>
        <md-input-chip label="Input"></md-input-chip>
        <md-suggestion-chip label="Suggestion"></md-suggestion-chip>
      </md-chip-set>

      <div class="container" style="max-width: 1000px">
        <div
          v-for="(item, index) in julyFeatures"
          :key="index"
          class="columns alterGrid is-vcentered"
          :class="{ 'reverse-columns-desktop': !(index % 2) }"
        >
          <div class="column is-6" style="padding-bottom: 0">
            <img :src="item.image" style="max-height: 300px; height: 30vw" />
          </div>
          <div class="column is-6">
            <div class="cardNO cardCity">
              <div>
                <div class="card-contentNO">
                  <!--
                  <span class="icon is-large is-left">
                    <i :class="'fal fa-' + item.icon"></i>
                  </span>  -->
                  <p class="title is-6 label">{{ item.label }}</p>
                  <p class="title is-2">{{ item.title }}</p>
                  <p class="subtitleNO desc">{{ item.desc }}</p>

                  <div v-if="item.btTo">
                    <router-link :to="item.btTo" class="button is-dark is-outlined bold" style="margin-top: 10px">
                      {{ item.btLabel || "Browse" }}
                    </router-link>
                  </div>

                  <div v-if="item.sampleSearch" style="margin-top: 10px">
                    <span class="title is-6" style="font-weight: bold; font-size: 14px">
                      <!-- arrow icon
                      <i class="far fa-search"></i>
                    -->
                      Try searching
                    </span>
                    <br />
                    <div style="margin-top: 10px">
                      <router-link
                        v-for="i in item.sampleSearch"
                        :key="i"
                        :to="`/search?q=${i}`"
                        class="button is- is-white is-small"
                        style="margin-right: 5px; margin-bottom: 5px"
                      >
                        {{ i }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 
              <footer class="card-footer">
                <p class="card-footer-item">
                  <a href="/quote" class="button is-link bold">GET STARTED</a>
                  <span v-if="item.demoUrl"
                    >&nbsp; &nbsp;
                    <a :href="item.demoUrl" target="_blank" class="button is-light">Demo</a>
                  </span>
                </p>
              </footer> -->
            </div>
          </div>
          <br /><br /><br />
        </div>
        <!-- eo features-->

        <div class="columns" style="align-items: center">
          <div class="column">
            <!-- buefy search input b-input with v-modelm submit on enter 

            <h1 class="title is-1">
            
              Bots for
              <span class="has-text-primary">
                <vue-typed-js :strings="['First text', 'Second Text']">
                  <h1 class="typing"></h1>
                </vue-typed-js>

                everything</span
              >
            </h1>---->

            <vue-typed-js :strings="botsFor" style="margin-bottom: 30px">
              <h1 class="title is-1">Bots for <span class="typing has-text-primary"></span></h1>
            </vue-typed-js>

            <form @submit.prevent="searchBots">
              <b-field>
                <!-- 
                    placeholder="Search 1,000+ AIs"

                -->
                <b-input
                  v-model="search"
                  placeholder="Find bots by name, description, etc..."
                  icon="fas fa-search"
                  expanded
                  rounded
                  size="is-large"
                  @keyup.native.enter="searchBots"
                ></b-input>
              </b-field>
            </form>
          </div>

          <div class="column is-4 is-hidden-mobile">
            <img
              src="https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/UwZ49jvFKL/OvCV-sfLi0___felix_m___3D_plastilina_ai_chatbot_dissasembled_on_clean_white__fb1453d7-ce3d-4abe-9f52-645799c8ff19.png?width=300&height=300&aspect_ratio=100:101"
              style="max-height: 200px; margin: auto; display: block; mix-blend-mode: multiply"
            />

            <!--
            <img
              src="https://cdn1.onlybots.cc/path/to/upload/folderehloTDTbng___face1.png"
              style="max-height: 200px; margin: auto; display: block"
            />
              -->
          </div>
        </div>

        <!-- for each cats show buttons, felx dipslya -->

        <div class="cat-buttons" style="margin-bottom: 45px">
          <router-link
            :to="`/${cat.value}`"
            class="button is-primary is-light"
            @click="searchBots(cat.value)"
            v-for="cat in cats"
            :key="cat.id"
          >
            <!-- icon ---->
            <span class="icon is-small">
              <i :class="'icon far fa-' + cat.icon"></i>
            </span>
            <span> {{ cat.label }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <FeaturedBots :blocks="blocks" />
    <div class="section">
      <div class="container home" v-if="!loadingProj">
        <!-- 
        <div class="columns is-multiline">
          <div class="column is-3-desktop is-12-tablet">
            <h1 class="title is-3">Your APIs</h1>
            <h1 class="subtitle is-5">Plan, prototype and manage API projects.</h1>
          

            <router-link :to="{ path: $store.main.state.userToken ? '/new' : '/login' }" active-class="is-active" exact>
              <b-button rounded>Add a new API</b-button>
            </router-link>
          </div>
          <div class="column">
            <div class="columns is-multiline">
              <div class="column is-6-tablet is-6-desktop is-4" v-for="i in projs" :key="i.id">
              
                <router-link :to="{ path: '/' + i.id }" active-class="is-active" exact>
                  <projCard :proj="i" />
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <router-link :to="{ path: '/explore' }">
          <b-button type="is-info is-outlined">Explore Public examples</b-button>
        </router-link>-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import FeaturedBots from "@/components/FeaturedBots.vue";
import botsTabs from "@/components/nav/botsTabs.vue";
import exploreTabs from "@/components/nav/exploreTabs.vue";

import Hero from "@/components/Hero.vue";

//import cats from "@/data/categories.js";

import Vue from "vue";
import VueTypedJs from "vue-typed-js";

Vue.use(VueTypedJs);

const cats = [
  //{ label: "Hot", value: "popular", icon: "fire" },

  { label: "Conversational", value: "conversational", icon: "comment" },
  { label: "Visual", value: "images", icon: "image" },
  { label: "Social", value: "social", icon: "users" },
  { label: "Editorial", value: "editorial", icon: "newspaper" },

  //{ label: "Popular", value: "popular", icon: "star" },

  /*
  { label: "All", value: "all", icon: "globe-americas" },
  ///utility, entertainement, assistance, education
  { label: "Entertainment", value: "entertainment", icon: "grin" },
  { label: "Utility", value: "utility", icon: "wrench" },
  { label: "Assistance", value: "assistant", icon: "user" },
  //shopping
  // { label: "Shopping", value: "shopping", icon: "shopping-cart" },
  //brands
  { label: "Brands", value: "brand", icon: "building" }, //user-headset concirege bell, bag

  */
  //{ label: "Fun", value: "fun", icon: "smile" },
  // { label: "Education", value: "education", icon: "graduation-cap" },
];

const julyFeatures = [
  // ING: https://icons8.com/illustrations/style--3d-plastic-people
  {
    label: "   SEARCH 🧪 ", //better search, ai drvine
    title: "Bye bye Google ", // , bye ads "Smart AI Search", // "Dive Deep with AI", // "Just the good stuff",
    // Revolutionize your -> Boost
    desc: "Talk to hundreds of smart AIs at once.  Quick answers, useful search results and personalized summaries, without the ads.",
    image: "/img/ilu/3d/search.png",
    sampleSearch: [
      "history of burkina faso",
      "I feel like 💩",
      " vegan tacos with hummus and pico ",
      "fun date ideas",
      "how to make a pizza",
      "33 usd in mexican pesos  ",
    ],
  },

  {
    label: "CHAT",
    title: "Unique chatbots", //"//Useful, helpful, fun and friendly and anything in between",
    desc: " Productivity tools, profesional advice, caring AI companions and anything in between. You deserve bots that fit your needs.",
    btLabel: "Explore chatbots",
    btTo: "/conversational",
    image: "/img/ilu/3d/content.png",
  },

  {
    label: "CREATE",
    title: "Generate images",
    desc: " Create AI art, stock photos or augmented selfies. Browse hundreds of templates and styles. No design skills required.",

    image: "/img/ilu/3d/zen.png",
  },

  {
    label: "SOCIAL",
    title: "Your own AI crew", // "Build your crew",
    desc: "Follow your favourite bots. Ask their advice in group chat. Get a curated content feed. Stay inspiried.",

    image: "/img/ilu/3d/office.png",
  },

  /*
  {
    label: "TECH", //"TECH / starting point / PRODUCT",
    title: "The best parts, combined",
    desc: "We package the best AI has to offer into familiar interfaces. Google-quality search results without the ads. Anxiety-free social feeds. Hundreds of fun and helpful chatbots.",
    //    desc: "We package the best AI models into familiar interfaces loved by billions.",
    image: "/img/ilu/3d/zen.png",
  },
  {
    label: "DISCOVER",
    title: "Good AI-generated content",
    desc: "Quality content without the noise. Whatever your interests are, OnlyBots is your endless source of inspiration and entertainment. Create, follow, remix - the future of content is now. It's fun, ad-free and personalized.",
    image: "/img/ilu/3d/content.png",
  },
  {
    label: "INBOX",
    title: "Your bots, your way",
    desc: "Redefine productivity with helpful and fun chatbots. Skip the copy-pasting of prompts and immediately dive into conquering your unique tasks.", //unique tasks, Niche tasks?
    image: "/img/ilu/3d/office.png",
  },

  {
    label: "EARN",
    title: "Empowering AI creators",
    desc: "Unleash your creativity to tap into the AI economy. Monetize captivating AI content, launch market-ready chatbots or design specialized tools. No coding skills? No problem!",
    image: "/img/ilu/3d/money.png",
  } */
];

export default {
  components: {
    FeaturedBots,
    //  botsTabs,
    exploreTabs,
    Hero,
    //  HelloWorld,
    //   projCard,
  },
  data() {
    return {
      blocks: [],
      search: "",
      projs: [],
      loadingProj: true,
      publi: [],
      botsFor: [
        "home chefs",
        "new parents",

        "dating advices",
        "artists", //artists creatives
        "app developers",
        "pope photos",
        "shopping tips",
        "brainstorming",
        "learners",

        "well-being", //  "mind care",
        "storytellers",
        "loads of fun",
        "career growth",
        //  "lifelong learners",
        "travelers",
        "home care",
        // "money management",
        "early adopters",
        "fitness",
        "tech support",
        "party planners",
        "gardening",
        "innovators",

        // "job seekers",
        "speaking spanish",
        // "techies",
        "shits and giggles",
        "everything",
      ],
      // loadingOps: true,
    };
  },
  methods: {
    async performSearch() {
      this.isLoading = true;
      var q = this.search;
      //  alert(3);
      // perform your search here, then update results and isLoading accordingly
      // the following is a placeholder and should be replaced with your own search code

      //redirect to search page
      //withj ?q=serch

      this.$router.push({ path: "/search", query: { q: q } });
    },
    searchBots() {
      // console.log("searching for", this.search);
      this.$router.push(`/explore-bots/${this.$route.params.cat || "all"}/${this.search}`);
      // this.$router.push({ name: "search", params: { query: this.search } });
    },
    load() {
      window.API.getFeaturedBots({ page: "exploreHome", format: "explore" }).then((res) => {
        //this.bots = proj;
        this.blocks = res;

        this.loading = false;
        window.prerenderReady = true;
        // console.log("PROJJ!", proj, proj.id);
      });
      /*
    window.API.getFeaturedBots({ brochureHome: 1, format: "audiences" }).then((proj2) => {
      //this.bots = proj;
      this.botCats = proj2;
      //  this.bots.push(promo);
      this.loadingBots = false;
      window.prerenderReady = true;
      // console.log("PROJJ!", proj, proj.id);
    });
    */
    },
  },
  computed: {
    cats() {
      return cats;
    },
    julyFeatures() {
      return julyFeatures;
    },
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.publi = proj;
      this.loadingProjPubli = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/

    var u = this.$store.main.state.userId;

    //  this.projs = proj;
    this.loadingProj = false;
    this.load();

    /*
    window.API.getUserProjects(u).then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
    /*
    window.API.getProjectOperations(this.$route.params.project).then((ops) => {
      var opId = this.$route.params.operation;
      this.ops = ops;
      this.loadingOps = false;
      this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
    });*/
  },
};
</script>
<style scoped>
.card {
  margin-bottom: 20px;
}

.cat-buttons {
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 12px;
}
.cat-buttons .button {
  padding: 30px 1px;
}

/* responsive mobile only */
@media only screen and (max-width: 768px) {
  .cat-buttons {
    grid-template-columns: repeat(3, auto);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .cat-buttons .button {
    justify-content: flex-start;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  /* <== You can change this break point as per your  needs */
  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media (min-width: 767px) {
  /* <== You can change this break point as per your  needs */
  .reverse-columns-desktop {
    flex-direction: column-reverse;
    flex-direction: row-reverse;
    display: flex;
  }
}

.label {
  opacity: 0.3;
  letter-spacing: 1px;
  font-weight: 200;
}

.alterGrid img {
  margin: auto;
  display: block;
}
</style>
